import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "../../context/CartContext";
import GeneralService from "../../services/general.service";
import Footer from "../Includes/Footer";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import "./CheckOut.css";
import Swal from "sweetalert2";
import Spinner from "../Includes/Spinner";
import emptyCart from "../Images/empty.gif"

export default function CheckOut() {
  const {
    totalPrice,
    updateCartCounter,
    increaseQty,
    removeItemFromCart,
    decreaseQty,
    userAddress,
    userInfo,
    cartItems,
    updateCartItems,
  } = useCart();
  console.log(JSON.stringify(cartItems));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState("");
  const [inputError, setInputError] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [minCharges, setMinCharges] = useState(0);
  const [cartCounter, setCartCounter] = useState(0);
  const [itemNo, setItemNo] = useState(0);

  // Fetch latest stock information periodically
  useEffect(() => {
    const fetchLatestStock = async () => {
      try {
        const response = await GeneralService.listAllProducts();
        const latestProducts = response.data.response;
        console.log(latestProducts);

        // Update cart items with the latest stock information
        const updatedCartItems = cartItems.map((item) => {
          const product = latestProducts.find(
            (product) => product.id === item.id
          );
          return product
            ? { ...item, stock_available: product.stock_available }
            : item;
        });

        updateCartItems(updatedCartItems);
      } catch (error) {
        console.error("Error fetching latest stock:", error);
      }
    };

    // Set interval to fetch the latest stock every 5 seconds
    const intervalId = setInterval(() => {
      if (cartItems.length > 0) {
        fetchLatestStock();
      }
    }, 5000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [cartItems, updateCartItems]);

  // Load saved address from secure local storage
  useEffect(() => {
    const savedAddress = secureLocalStorage.getItem("address");
    if (savedAddress) {
      setAddress(savedAddress);
    }
  }, []);

  // Fetch data when cart items change
  useEffect(() => {
    if (Array.isArray(cartItems)) {
      fetchData();
      fetchDeliveryCharges();
      fetchMinCharges();
    }
  }, [cartItems]);

  const fetchData = async () => {
    try {
      const totalPriceCalculated = cartItems.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.quantity * currentValue.price;
        },
        0
      );

      const numberOfItems = cartItems.length;

      setItemNo(numberOfItems);
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  const fetchDeliveryCharges = async () => {
    try {
      const response = await GeneralService.getChargesByPrice(totalPrice);
      const { data } = response;
      console.log(data);
      const { response: res } = data;
      setDeliveryCharges(Number(res.price)); // Convert to number
    } catch (err) {
      setDeliveryCharges(0);
    }
  };

  const fetchMinCharges = async () => {
    try {
      const response = await GeneralService.getMinCharges();
      const { data } = response;
      const { response: res } = data;
      setMinCharges(Number(res.price)); // Ensure it's a number
    } catch (err) {
      setMinCharges(0);
    }
  };

  const handleInputChange = (text) => {
    setAddress(text);
  };

  const placeOrder = async () => {
    if (!secureLocalStorage.getItem("id")) {
      Swal.fire({
        title: "Authentication Required",
        text: "Please log in to place an order.",
        icon: "warning",
        confirmButtonColor: "#28a745",
      });
      navigate("/sign-in", {
        state: {
          page: "/cart",
        },
      });
    } else if (parseFloat(totalPrice) >= parseFloat(minCharges)) {
      // Ensure numeric comparison
      if (address) {
        setInputError("");
      } else {
        setInputError("Please enter your delivery address.");
        return; // Prevent navigation if address is missing
      }

      navigate("/payment", {
        state: {
          cart: cartItems,
          total: totalPrice,
          delivery: deliveryCharges,
          items: itemNo,
          address: address,
        },
      });
    } else if (parseFloat(totalPrice) < parseFloat(minCharges)) {
      // Ensure numeric comparison
      Swal.fire({
        title: "Minimum Order Not Met",
        text: `The minimum order amount is Rs ${minCharges}. Please add more items to your cart.`,
        icon: "warning",
        confirmButtonColor: "#28a745",
      });
      if (address) {
        setInputError("");
      } else {
        setInputError("Please enter your delivery address.");
      }
    }
  };

  return (
    <>
      {/* Header */}
      <header className="header py-3 px-4">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <Link to="/" className="text-decoration-none">
                  <i className="bi bi-arrow-left text-success h3 m-0 back-page"></i>
                </Link>
                <h3 className="fw-bold m-0 ms-3">Shopping Cart</h3>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="container checkout-container">
        {cartItems.length === 0 ? (
          <div className="empty-cart text-center my-5">
            <img
              src={emptyCart}
              alt="Empty Cart"
              className="mb-4"
            />
            <h4>Your cart is empty!</h4>
            <p>Add some delicious items to your cart to proceed.</p>
            <Link to="/" className="btn btn-primary mt-3">
              Back to Shopping
            </Link>
            <Spinner />
          </div>
        ) : (
          <div className="row">
            {/* Left Side - Cart Items */}
            <div className="col-lg-8 col-md-12 mb-2">
              <div className="cart-items">
                {cartItems.map((item, index) => (
                  <div
                    key={index}
                    className="cart-item bg-white p-3 shadow-sm rounded-3 mb-4"
                  >
                    <div className="d-flex align-items-center">
                      {/* Trash Button */}
                      <div
                        className="trash-button me-3"
                        onClick={() => removeItemFromCart(item)}
                        title="Remove Item"
                        aria-label="Remove Item"
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </div>

                      {/* Product Image */}
                      <img
                        src={`https://api.veggieking.pk/public/upload/${item.image}`}
                        alt={item.name}
                        className="cart-item-image rounded"
                        loading="lazy" // Optional: Lazy loading
                      />

                      {/* Product Details */}
                      <div className="cart-item-details ms-3 flex-grow-1">
                        <h6 className="fw-bold mb-2">{item.name}</h6>
                        <p className="mb-1">Price: Rs. {item.price}</p>
                        <p className="mb-1">Quantity: {item.quantity}</p>
                        <p className="mb-0">
                          Subtotal: Rs. {item.price * item.quantity}
                        </p>
                      </div>

                      {/* Quantity Control */}
                      <div className="quantity-control d-flex align-items-center">
                        {item.stock_available > 0 ? (
                          <>
                            <button
                              className="btn btn-sm btn-outline-success"
                              onClick={() => decreaseQty(item)}
                              disabled={item.quantity <= 1}
                              aria-label="Decrease quantity"
                            >
                              <i className="fa-solid fa-minus"></i>
                            </button>
                            <span className="mx-2">{item.quantity}</span>
                            <button
                              className="btn btn-sm btn-outline-success"
                              onClick={() => {
                                if (
                                  parseInt(item.quantity) <
                                    parseInt(item.stock_available) &&
                                  parseInt(item.quantity) <
                                    parseInt(item.max_qty)
                                ) {
                                  increaseQty(item);
                                } else {
                                  toast.info(
                                    "Maximum quantity reached or item out of stock."
                                  );
                                }
                              }}
                              aria-label="Increase quantity"
                            >
                              <i className="fa-solid fa-plus"></i>
                            </button>
                          </>
                        ) : (
                          <span className="badge bg-danger">Out of Stock</span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Right Side - Price Summary */}
            <div className="col-lg-4 col-md-12">
              <div className="summary-section bg-white p-4 rounded-3 shadow-sm">
                {/* Delivery Address */}
                {secureLocalStorage.getItem("id") && (
                  <div className="delivery-address mb-4">
                    <label
                      htmlFor="delivery_address"
                      className="form-label fw-bold"
                    >
                      Delivery Address
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        inputError ? "is-invalid" : ""
                      }`}
                      id="delivery_address"
                      placeholder="Enter your delivery address"
                      value={address}
                      onChange={(e) => handleInputChange(e.target.value)}
                      aria-describedby="deliveryAddressError"
                    />
                    {inputError && (
                      <div
                        id="deliveryAddressError"
                        className="invalid-feedback"
                      >
                        {inputError}
                      </div>
                    )}
                  </div>
                )}

                {/* Pricing Details */}
                <div className="pricing-details mb-4">
                  <div className="d-flex justify-content-between">
                    <span>
                      Subtotal ({itemNo} {itemNo > 1 ? "items" : "item"})
                    </span>
                    <span>Rs. {Number(totalPrice).toFixed(2)}</span>
                  </div>
                  {/* <div className="d-flex justify-content-between mt-2">
                                        <span>Delivery Charges</span>
                                        <span>Rs. {Number(deliveryCharges).toFixed(2)}</span>
                                    </div> */}
                  <hr />
                  <div className="d-flex justify-content-between fw-bold">
                    <span>Total</span>
                    <span>
                      Rs.{" "}
                      {(Number(totalPrice) + Number(deliveryCharges)).toFixed(
                        2
                      )}
                    </span>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-success btn-lg"
                    onClick={placeOrder}
                  >
                    Place Order
                  </button>
                  <Link to="/" className="btn btn-secondary btn-lg">
                    Back to Shopping
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>

      {/* Footer */}
      <Footer counter={cartCounter} />
    </>
  );
}
